import * as React from "react";
import logo from "../images/UXC-Portal Logo.png";

const Navigation = () => {
  var menuOpen = false;

  function css(element, style) {
    for (const property in style) element.style[property] = style[property];
  }

  function toggleNavigation() {
    menuOpen = !menuOpen;
    const nav = document.querySelector(".mobile-navigation");
    if (menuOpen) {
      css(nav, {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        padding: "1em 0",
      });
    } else {
      css(nav, {
        display: "none",
      });
    }
  }

  return (
    <nav>
      <div className="mobile-navigation" id="mobile-navigation">
        <a href="/about">
          <div className="mobile-navigation__link">About us</div>
        </a>
        <a href="/contact">
          <div className="mobile-navigation__link">Contact us</div>
        </a>
      </div>
      <div className="logo-container">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
        <a href="#mobile-navigation" onClick={() => toggleNavigation()}>
          <i class="fas fa-bars"></i>
        </a>
      </div>
      <div className="navigation">
        <a href="/about">
          <div className="navigation__link">About us</div>
        </a>
        <a href="/contact">
          <div className="navigation__link">Contact us</div>
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
